.header {
  background: #ECEFF7;
  height: 60px;

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    width: 30%;
    display: flex;
    align-items: center;
  }
  &__nav {
    width: 70%;
    .nav {
      justify-content: flex-end;
    }
  }
}