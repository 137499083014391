.chat {
  background: url('../img/chat/chat_bg.png') no-repeat center center / cover;
  border-radius: 15px 15px 0px 0px;
  max-width: 1140px;
  width: 100%;
  margin: 40px auto 0;
  padding: 60px 0 100px;
  min-height: 100%;

  .row {
    margin: 0 0 15px;
  }
}

.message {
  max-width: 457px;
  padding: 35px;
  box-shadow: 15px 15px 30px rgba(21, 11, 139, 0.1);
  position: relative;
  &::before {
    position: absolute;
    content: "";
    border-left: 15px solid #ffffff;
    border-bottom: 0px solid #ffffff;
    border-top: 15px solid transparent;
    border-right: 0px solid #fff;
    bottom: 0;
  }
  &_bot {
    background: #ffffff;
    border-radius: 15px 15px 15px 0px;
    &::before {
      left: -14px;
      transform: rotate(-90deg);
    }
  }
  &_user {
    background: #dbe5ff;
    border-radius: 15px 15px 0px 15px;
    &::before {
      right: -14px;
      border-left: 15px solid #dbe5ff;
      border-bottom: 0px solid #dbe5ff;
      border-top: 15px solid transparent;
      border-right: 0px solid #dbe5ff;
    }
  }
  &_confirm {
    background: linear-gradient(0deg, #27AE60, #27AE60), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
    color: #ffffff;
    &::before {
      border-left: 15px solid #27AE60;
      border-bottom: 0px solid #27AE60;
      border-top: 15px solid transparent;
      border-right: 0px solid #27AE60;
    }
  }
  &.animated {
    animation-duration: 3s;
  }
}

.wait {
  position: relative;
  color: #7b8399;
  font-size: 12px;
  line-height: 15px;
  &::after {
    position: absolute;
    content: "...";
    color: #7b8399;
    right: -30px;
    bottom: 0;
    font-size: 40px;
    line-height: 0.85;
    font-weight: bold;
  }
  &.animated {
    animation-duration: 3s;
  }
}

.form {
  background: linear-gradient(0deg, #dbe5ff, #dbe5ff), #ffffff;
  box-shadow: 15px 15px 30px rgba(21, 11, 139, 0.1);
  border-radius: 15px;
  max-width: 555px;
  width: 100%;
  &__input {
    width: 100%;
  }
  &_input,
  &_text {
    background: #0c0097;
    border: 7px solid #dbe5ff;
    border-radius: 15px;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
  }
  &_input &,
  &_text & {
    &__input {
      height: 32px;
      width: 83%;
      color: #fff;
      position: relative;
      & input {
        &:focus {
          & + span {
            display: none;
          }
        }
      }
      & span {
        position: absolute;
        width: 1px;
        height: 24px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #ffffff;
        &.animated {
          animation-duration: 3s;
        }
      }
    }
    &__button {
      width: 15%;
      background: linear-gradient(0deg, #f55050, #f55050), linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
      border-radius: 15px;
      cursor: pointer;
      max-height: 30px;
      &:hover {
        background: linear-gradient(0deg, #ff7373, #ff7373), linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
      }
      &:active {
        background: linear-gradient(0deg, #ff9595, #ff9595), linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
      }
    }
  }
  &_submit {
    padding: 25px;
  }
  &_submit & {
    &__input {
      min-height: 50px;
      background: linear-gradient(0deg, #0c0097, #0c0097), #ffffff;
      box-shadow: 15px 15px 30px rgba(21, 11, 139, 0.1);
      border-radius: 15px;
      margin-bottom: 15px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        background: #0f00c1;
      }
      &:active {
        background: #1200df;
      }
      & input {
        display: none;
      }
    }
    &__submit {
      text-align: center;
      cursor: pointer;
    }
  }
  &_text {
    & textarea {
      display: none;
    }
  }
  &_text & {
    &__input {
      height: initial;
    }
    &__textfield {
      width: 100%;
      height: 100%;
      outline: none;
      &:focus {
        & + span {
          display: none;
        }
      }
    }
  }
}
