.footer {
  background: #ECEFF7;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  &__nav {
    height: 60px;
    display: flex;
    align-items: center;
  }
}