.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__link {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #333333;
    margin-right: 30px;
    &:hover {
      color: #333333;
      opacity: 0.75;
    }
    &:active {
      color: #333333;
      opacity: 0.5;
    }
    &:last-of-type {
      margin-right: 0;
    }
    & img {
      margin-right: 8px;
    }
    &.close {
      & img {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}