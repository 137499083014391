@font-face {
	font-family: 'Proxima Nova';
	src: url('fonts/ProximaNova-Regular.eot');
	src: url('fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/ProximaNova-Regular.woff2') format('woff2'),
		url('fonts/ProximaNova-Regular.woff') format('woff'),
		url('fonts/ProximaNova-Regular.ttf') format('truetype'),
		url('fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}